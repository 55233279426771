import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Button, Flex, HStack, Spacer, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { ColDef, DataTable } from '../datatable'
import { IAttachedFile, ITask, TypeOfTask } from '@/utils/types'
import { useCompleteTask } from '@/hooks/useCompleteTask'
import { TaskStatus, useGetTasks } from '@/hooks/useGetTasks'
import { Element, useMultiSelectFilter } from '@/hooks/useMultiSelectFilter'
import { useTranslation } from '@/utils/i18n'
import { MdCancel, MdOutlineOpenInNew } from 'react-icons/md'
import { useRouter } from 'next/router'
import { TaskModal } from './taskModal'
import CreateTask from './createTask'
import { FaEquals } from 'react-icons/fa6'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { TiAttachmentOutline } from 'react-icons/ti'
import { FaCheckCircle } from 'react-icons/fa'
import { AiOutlineLoading } from 'react-icons/ai'
import ImagePreview from './imagePreview'
import { RiListCheck, RiProgress6Fill } from 'react-icons/ri'
import EditTask from './editTask'
import { useUpdateTaskStatus } from '@/hooks/useUpdateTaskStatus'
import { useGetTasksCategories } from '@/hooks/useGetTasksCategories'
import { useGetMeContext } from '@/contexts'
import { useGetPharmacyUserDetailsByPharmacyId } from '@/hooks/useGetPharmacyUserDetailsByPharmacyId'

export const TypeOfTaskColorScheme = {
  insurance: 'blue',
  transfer: 'purple',
  toVerifyByPharmacist: 'pink',
  order: 'yellow',
  paid: 'blue',
  medicalInfo: 'orange',
  newUser: 'purple',
  idVerification: 'red',
  addressUpdate: 'cyan',
  addressDeleted: 'teal',
  addressAdded: 'teal',
  deleteInsurance: 'gray',
  newNotification: 'gray',
  medium: 'orange',
  high: 'red',
  standard: 'blue'
}

export const Tasks = () => {
  const NUMBER_OF_COLOR_SCHEME = 17
  const [selectedTask, setSelectedTask] = useState<ITask[]>([])
  const t = useTranslation()
  const { completeTask } = useCompleteTask()
  const { me } = useGetMeContext()
  const { data: pharmacyUser } = useGetPharmacyUserDetailsByPharmacyId({
    oAuthId: me.oAuthId
  })

  const [statusFilter, setStatusFilter] = useState<TaskStatus[]>(['todo', 'inprogress'])
  const [taskCategoryFilter, setTaskCategoryFilter] = useState<TypeOfTask[]>([])
  const { data: taskTypes, loading: isLoadingTaskCategories } = useGetTasksCategories()

  const { data, loading, refetch } = useGetTasks({
    status: statusFilter,
    typeOfTask: (taskCategoryFilter ?? [])?.map(filter => filter?.id)
  })

  const { updateTaskStatus, updateTaskStatusQuery } = useUpdateTaskStatus()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState<ITask>(null)

  let tasks = data?.tasks
  const router = useRouter()

  let colorSchemeMap = new Map<number, number>()
  const typeOfTaskColorScheme = (typeOfTaskId: number) => {
    if (!colorSchemeMap.has(typeOfTaskId)) {
      colorSchemeMap.set(typeOfTaskId, colorSchemeMap.size % NUMBER_OF_COLOR_SCHEME + 1)
    }
    switch (colorSchemeMap.get(typeOfTaskId)) {
      case 1:
        return 'blackAlpha'
      case 2:
        return 'gray'
      case 3:
        return 'red'
      case 4:
        return 'orange'
      case 5:
        return 'yellow'
      case 6:
        return 'green'
      case 7:
        return 'teal'
      case 8:
        return 'blue'
      case 9:
        return 'cyan'
      case 10:
        return 'purple'
      case 11:
        return 'pink'
      case 12:
        return 'linkedin'
      case 13:
        return 'facebook'
      case 14:
        return 'messenger'
      case 15:
        return 'whatsapp'
      case 16:
        return 'twitter'
      case 17:
        return 'telegram'
      default:
        return 'blue'
    }
  }

  const statusText = (status: string) => {
    switch (status) {
      case 'in-progress':
      case 'inprogress':
        return t('tasks.inProgress')
      case 'todo':
        return t('tasks.toDo')
      case 'completed':
        return t('tasks.completed')
      case 'canceled':
        return t('tasks.cancelled')
      default:
        return t('tasks.toDo')
    }
  }

  const { Filter: FilterStatus } = useMultiSelectFilter<TaskStatus>({
    elements: (['todo', 'inprogress', 'canceled', 'completed'] as TaskStatus[]).map((status: TaskStatus) => (
      {
        value: status,
        text: statusText(status)
      }
    )),
    isLoading: loading,
    selectedElements: statusFilter.map(status => ({ value: status, text: status })),
    onFilterChange: (newSelection: Element<TaskStatus>[]) => {
      setStatusFilter(newSelection.map(ele => ele.value))
      refetch()
    }
  })

  const { Filter: FilterTaskCategory } = useMultiSelectFilter<TypeOfTask>({
    elements: (taskTypes?.taskCategories ?? []).map(userTaskCategory => (
      {
        value: userTaskCategory,
        text: router.locale === 'en' ? userTaskCategory?.name : userTaskCategory?.nameFr
      }
    )),
    isLoading: isLoadingTaskCategories,
    selectedElements: taskCategoryFilter.map(userTaskCategory => ({
      value: userTaskCategory,
      text: router.locale === 'en' ? userTaskCategory?.name : userTaskCategory?.nameFr
    })),
    onFilterChange: (newSelection: Element<TypeOfTask>[]) => {
      setTaskCategoryFilter(newSelection.map(ele => ele.value))
      refetch()
    }
  })

  useEffect(() => {
    if (taskTypes?.taskCategories) {
      setTaskCategoryFilter(taskTypes?.taskCategories)
    }
  }, [taskTypes?.taskCategories])

  const [taskId, setTaskId] = useState(null)

  const priorityToInt = (priority: string): number => {
    switch (priority) {
      case 'high':
        return 3
      case 'medium':
        return 2
      case 'standard':
        return 1
      default:
        return 0
    }
  }

  const priorityIcon = (priority: string) => {
    switch (priority) {
      case 'high':
        return <IoIosArrowUp color="red" />
      case 'medium':
        return <FaEquals color="orange" />
      default:
        return <IoIosArrowDown color="blue" />
    }
  }

  const renderTaskStatus = (row: ITask) => {
    return row?.completed ? (
      <Badge colorScheme="blue" variant="outline">
        {t('tasks.completed')}
      </Badge>
    ) : (
      <Badge colorScheme="red" variant="outline">
        {statusText(row.status)}
      </Badge>
    )
  }

  const doneTask = async (taskId: number) => {
    await completeTask({
      variables: {
        taskId: taskId,
        notifyUser: true
      }
    })
    refetch()
  }

  const updateStatus = async (taskId: number, status: string) => {
    setTaskId(taskId)
    await updateTaskStatus({
      variables: {
        taskId: taskId,
        status: status
      }
    })
    refetch()
  }

  const renderAction = (row: ITask) => {
    return (
      <>
        {updateTaskStatusQuery?.loading && row.taskId === taskId ? <AiOutlineLoading className="spin" /> :
          < Box display="flex" alignItems="center">
            <Tooltip shouldWrapChildren hasArrow label={t('tasks.tooltip.todo')}>
              <RiListCheck cursor="pointer" style={{ width: '18px', height: '18px' }} color="black"
                           onClick={() => updateStatus(row?.taskId, 'todo')} />
            </Tooltip>
            <Tooltip shouldWrapChildren label={t('tasks.tooltip.in_progress')}>
              <RiProgress6Fill cursor="pointer" style={{ width: '20px', height: '20px' }} color="#00B5D8"
                               onClick={() => updateStatus(row?.taskId, 'inprogress')} />
            </Tooltip>
            <Tooltip shouldWrapChildren label={t('tasks.tooltip.done')}>
              <FaCheckCircle cursor="pointer" style={{ width: '18px', height: '18px' }} color="green"
                             onClick={() => doneTask(row?.taskId)} />
            </Tooltip>
            {pharmacyUser?.getPharmacyUserDetailsByPharmacyId.isAdmin && (
              <Tooltip shouldWrapChildren label={t('tasks.tooltip.cancelled')}>
                <MdCancel cursor="pointer" style={{ width: '20px', height: '20px' }} color="red"
                          onClick={() => updateStatus(row?.taskId, 'canceled')} />
              </Tooltip>
            )}
            <Tooltip shouldWrapChildren label={t('tasks.tooltip.edit')}>
              <EditTask row={row} refetch={() =>
                refetch()
              } />
            </Tooltip>
            {!row.manuallyCreated && (
              <Tooltip shouldWrapChildren label={t('tasks.tooltip.view')}>
                <TaskModal onDoneTask={doneTask} task={row} />
              </Tooltip>
            )}
          </Box>}
      </>
    )
  }

  const renderUser = (row: ITask) => {
    return (
      <HStack
        spacing={1}
        cursor="pointer"
        _hover={{ color: 'blue.500' }}
        onClick={() => {
          router.push(`/users/${row?.oAuthId?.substring(6)}`)
        }}
      >
        <Text>
          {row?.userFirstName} {row?.userLastName}
        </Text>
        <MdOutlineOpenInNew />
      </HStack>
    )
  }

  const handleRowItemClick = (row: ITask) => {
    setSelectedRow(row)
    onOpen()
  }

  const cols: ColDef<any>[] = [
    {
      colName: t('tasks.priority'),
      render: (row: ITask) =>
        row?.priority ? (
          <Tooltip label={t(`priority.${row.priority}`)}>
            <Text>{priorityIcon(row.priority)}</Text>
          </Tooltip>
        ) : null,
      key: 'priority',
      compareFn: (a: ITask, b: ITask) => {
        const priorityA = priorityToInt(a.priority)
        const priorityB = priorityToInt(b.priority)
        return priorityA > priorityB ? 1 : (priorityA < priorityB ? -1 : 0)
      }
    },
    {
      colName: t('tasks.type'),
      colRender: FilterTaskCategory,
      render: (row: ITask) => (
        <Badge colorScheme={typeOfTaskColorScheme(row?.taskTypeCategory?.id) || 'blue'} variant="solid">
          {router.locale === 'en' ? row?.taskTypeCategory?.name : row?.taskTypeCategory?.nameFr}
        </Badge>
      ),
      key: 'type',
      compareFn: (a: ITask, b: ITask) => {
        if (a?.typeOfTask < b?.typeOfTask) {
          return -1
        }
        if (a?.typeOfTask > b?.typeOfTask) {
          return 1
        }
        return 0
      }
    },
    {
      colName: t('tasks.title'),
      render: (row: ITask) => {
        if (row?.title) {
          return `${row?.title}`
        } else {
          return ''
        }
      },
      compareFn: (a: ITask, b: ITask) => {
        if (a?.title?.toLowerCase() < b?.title?.toLowerCase()) {
          return -1
        }
        if (a?.title?.toLowerCase() > b?.title?.toLowerCase()) {
          return 1
        }
        return 0
      },
      key: 'title'
    },
    {
      colName: t('tasks.patient'),
      render: renderUser,
      compareFn: (a: ITask, b: ITask) => {
        if (a?.userFirstName?.toLowerCase() < b?.userFirstName?.toLowerCase()) {
          return -1
        }
        if (a?.userFirstName?.toLowerCase() > b?.userFirstName?.toLowerCase()) {
          return 1
        }
        return 0
      },
      key: 'user'
    },
    {
      colName: t('tasks.establishmentEmployee'),
      render: (row) =>
        row?.assignedTo && row?.assignedTo?.[0]?.fullName ? (
          <Flex wrap="wrap" gridGap={2}>
            {row?.assignedTo?.map(({ oAuthId, fullName }) => (
              <HStack
                key={oAuthId}
                spacing={1}
                cursor="pointer"
                _hover={{ color: 'blue.500' }}
                onClick={() => {
                  router.push(`/users/${oAuthId?.substring(6)}`)
                }}
              >
                <Tooltip label={fullName}>
                  <Text as="label">
                    <Avatar name={fullName} size="sm" cursor="pointer" />
                  </Text>
                </Tooltip>
              </HStack>
            ))}
          </Flex>
        ) : null,
      key: 'establishmentEmployee'
    },
    {
      colName: t('tasks.date'),
      render: (row: ITask) => `${moment(row?.createdAt).format('DD MMM, YY')}`,
      compareFn: (a: ITask, b: ITask) => {
        if (moment(a?.createdAt).toDate() < moment(b?.createdAt).toDate()) {
          return -1
        }
        if (moment(a?.createdAt).toDate() > moment(b?.createdAt).toDate()) {
          return 1
        }
        return 0
      },
      key: 'date'
    },
    {
      colName: t('tasks.dueDate'),
      render: (row: ITask) => (row.dueDate ? <Text>{moment(row?.dueDate).format('DD MMM, YY | HH:00 A')}</Text> : null),
      key: 'dueDate',
      compareFn: (a: ITask, b: ITask) => {
        if (moment(a?.dueDate).toDate() < moment(b?.dueDate).toDate()) {
          return -1
        }
        if (moment(a?.dueDate).toDate() > moment(b?.dueDate).toDate()) {
          return 1
        }
        return 0
      }
    },
    {
      colName: statusFilter.includes('completed') ? t('tasks.completed') : null,
      render: (row: ITask) => (row.completedAt ? <Text>{moment(row?.completedAt).format('DD MMM, YY')}</Text> : null),
      hide: !statusFilter.includes('completed'),
      key: 'completedDate'
    },
    {
      // Attachment
      colName: <TiAttachmentOutline fontSize={20} />,
      render: (row: ITask) =>
        row.attachedFileIds?.length ? (
          <Flex wrap="wrap" gridGap={2}>
            {
              row?.attachedFileIds?.map(({ filename, imageId }: IAttachedFile) => <ImagePreview key={imageId}
                                                                                                imagesIds={[imageId]}
                                                                                                text={filename} />)
            }
          </Flex>
        ) : null,
      key: 'attachment'
    },
    {
      colName: t('common.status'),
      render: renderTaskStatus,
      key: 'status',
      colRender: FilterStatus
    },
    {
      colName: t('tasks.action'),
      render: renderAction,
      key: 'action',
      hide: statusFilter.includes('completed')
    }
  ]

  const handleDoneAll = async () => {
    await Promise.all(
      selectedTask.map(
        async (task) =>
          await completeTask({
            variables: { taskId: task.taskId, notifyUser: true }
          })
      )
    )
    refetch()
    setSelectedTask([])
  }

  return (
    <Box>
      <Flex w="full" alignItems="center" px={4} pb={4} justifyContent="space-between">
        <Spacer />
        <Flex gridGap={2}>
          <CreateTask
            refetch={() =>
              refetch()
            }
          />
          {!!selectedTask?.length && (
            <Button colorScheme="blue" variant="solid" size="sm" onClick={handleDoneAll}>
              {t('tasks.doneAll')}
            </Button>
          )}
        </Flex>
      </Flex>
      <Box bg="white" mx={4} borderWidth={1} borderRadius="md">
        <DataTable cols={cols} rowPadding={3} rows={tasks} isMultiSelect={!statusFilter.includes('completed')}
                   isLoading={loading} onSelectedRowChange={setSelectedTask}
                   selectedRowItems={selectedTask}></DataTable>
      </Box>
      {/*{selectedRow && isOpen &&*/}
      {/*  <TaskModal isOpen={isOpen} onClose={onClose} onDoneTask={doneTask} task={selectedRow} />}*/}
    </Box>
  )
}
