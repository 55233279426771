import React, { useEffect, useState } from 'react'
import { Box, Table, Tbody, Text, Td, Th, Thead, Tr, Checkbox, theme, Flex } from '@chakra-ui/react'
import { usePaginate } from './usePaginate'
import { PaginationControl } from './PaginationControl'
import { Loader } from '../loader'
import { useSort } from './useSort'
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'
import { useTranslation } from '@/utils/i18n'

export interface ColDef<T> {
  colName?: string;
  colRender?: () => any;
  key: string;
  render?: (row: T) => any;
  position?: 'center' | 'start' | 'end';
  compareFn?: (a: any, b: any) => number;
  hide?: boolean;
}

export interface IDataTable<T> {
  cols: ColDef<T>[]
  rows: T[]
  isLoading?: boolean
  rowPadding?: number
  sidePadding?: boolean
  showPagination?: boolean
  showRowBorder?: boolean
  showHeader?: boolean
  serverPaginate?: { page: number; size: number; totalCount: number }
  onPageUpdate?: (page: number) => void
  onSizeUpdate?: (size: number) => void
  onRowItemClick?: (row: T) => void
  defaultPageSize?: number
  paginationPosition?: 'start' | 'center' | 'end'
  isMultiSelect?: boolean
  selectedRowItems?: T[]
  onSelectedRowChange?: React.Dispatch<React.SetStateAction<T[]>>
  tableKey?: string
}

const styles: any = {
  tableContainerWithSpace: {
    overflow: 'hidden',
    '& td': {
      padding: `${theme.space[0.5]} ${theme.space[4]}`
    },
    '& th': {
      padding: `${theme.space[1]} ${theme.space[4]}`,
      paddingTop: `${theme.space[2]} `
    }
  },
  tableContainerWithOutSpace: {
    overflow: 'hidden',
    '& td': {
      padding: `${0} ${0}`
    },
    '& th': {
      padding: `${theme.space[1]} ${0}`
    }
  }
}

export const DataTable = <T extends { id: string }>({
  cols,
  rows,
  showPagination = true,
  showRowBorder = true,
  showHeader = true,
  sidePadding = true,
  isLoading = false,
  serverPaginate,
  onPageUpdate,
  onSizeUpdate,
  rowPadding = 0,
  defaultPageSize,
  onRowItemClick,
  paginationPosition = 'end',
  isMultiSelect = false,
  selectedRowItems,
  onSelectedRowChange,
  tableKey
}: IDataTable<T>) => {
  const t = useTranslation()
  const size = serverPaginate?.size || defaultPageSize || 10

  const { sortedRows, sort, activeSortColKey, isAscending } = useSort({ rows })
  const { hasNextPage, hasPreviousPage, hasFirstPage, hasLastPage, next, pageSize, previous, firstPage, lastPage, currentPage, totalCount, currentPageData, updateSize } = usePaginate<T>({
    data: sortedRows,
    size,
    serverSide: !!serverPaginate,
    totalCount: serverPaginate?.totalCount,
    onPageUpdate,
    onSizeUpdate
  })

  useEffect(() => {
    firstPage()
  }, [activeSortColKey])

  const [isAllChecked, setAllChecked] = useState<boolean>(false)

  const checkRow = (isChecked: boolean, row: T) => {
    setAllChecked(false)
    isChecked ? onSelectedRowChange((rows) => [...rows, row]) : onSelectedRowChange((rows) => rows.filter((rw) => rw?.id !== row?.id))
  }

  const checkAllRow = (e) => {
    e.target.checked ? onSelectedRowChange(currentPageData) : onSelectedRowChange([])
    setAllChecked(e.target.checked)
  }

  useEffect(() => {
    onSelectedRowChange && onSelectedRowChange(selectedRowItems)
    selectedRowItems?.length < 1 && setAllChecked(false)
  }, [selectedRowItems])

  return (
    <Box sx={sidePadding ? styles.tableContainerWithSpace : styles.tableContainerWithOutSpace}>
      <Table variant="unstyled" size="sm">
        {showHeader && (
          <Thead borderBottom={`1px solid ${theme.colors.gray[200]}`}>
            <Tr>
              {isMultiSelect && (
                <Th width={50}>
                  <Box display="flex" pb={theme.sizes[1.5]}>
                    <Checkbox size="md" isChecked={isAllChecked} onChange={checkAllRow} />
                  </Box>
                </Th>
              )}
              {cols.map((col: ColDef<T>) => (
                <Th
                  key={col.key}
                  display={col.hide && 'none'}
                >
                  <Box pb={theme.sizes[1.5]} display="flex" justifyContent={col.position === 'end' ? 'flex-end' : col.position === 'center' ? 'center' : 'flex-start'}>
                    {' '}
                    {col?.colName || ''}
                    {' '}
                    {col?.colRender?.()}
                    {
                      col?.compareFn && activeSortColKey !== col.key && (
                        <Flex
                          cursor='pointer'
                          onClick={() => { col?.compareFn && sort(col.compareFn, col.key) }}
                        >
                          <MdArrowUpward style={{ margin: 0, padding: 0 }} />
                          <MdArrowDownward style={{ margin: 0, padding: 0, left: -2 }} />
                        </Flex>
                      )
                    }
                    {activeSortColKey === col.key && (
                      <Flex cursor='pointer' ml={2} onClick={() => { col?.compareFn && sort(col.compareFn, col.key) }}>
                        {isAscending ? <MdArrowUpward /> : <MdArrowDownward />}
                      </Flex>
                    )}
                  </Box>
                </Th>
              ))}
            </Tr>
          </Thead>
        )}

        <Tbody backgroundColor="white">
          {!isLoading &&
            currentPageData?.map((row: T) => {
              return (
                <Tr
                  key={row?.id ?? row?.[tableKey]}
                  _hover={{ background: 'brand.50' }}
                  borderBottomColor="gray.100"
                  bg={selectedRowItems?.find((rw) => rw.id === row.id) ? 'blue.50' : 'white'}
                  borderBottomWidth={showRowBorder ? 1 : 0}
                  borderBottomStyle="solid"
                >
                  {isMultiSelect && (
                    <Td width={50}>
                      <Box display="flex">
                        <Checkbox
                          size="md"
                          isChecked={!!selectedRowItems?.find((rw) => rw.id === row.id)}
                          onChange={(e) => {
                            checkRow(e.target.checked, row)
                          }}
                        />
                      </Box>
                    </Td>
                  )}
                  {cols.map((col, index) => {
                    return (
                      <Td
                        key={`${col.key}_${index}`}
                        py={4}
                        display={col.hide && 'none'}
                        onClick={(e) => {
                          onRowItemClick && onRowItemClick(row)
                        }}
                      >
                        {col.render && (
                          <Box width="100%" py={rowPadding} display="flex" fontSize="sm" justifyContent={col.position === 'end' ? 'flex-end' : col.position === 'center' ? 'center' : 'flex-start'}>
                            {col.render(row)}
                          </Box>
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
        </Tbody>
      </Table>
      {isLoading && (
        <Box width="100%" display="flex" justifyContent="center" p={4} borderBottom={`1px solid ${theme.colors.gray[200]}`}>
          <Loader fullWidth />
        </Box>
      )}
      {!isLoading && !currentPageData?.length && (
        <Box w="100%" borderBottom={`1px solid ${theme.colors.gray[200]}`} display="flex" justifyContent="center" p={2}>
          <Text>{t('common.noResultFound')}</Text>
        </Box>
      )}
      {showPagination && (
        <PaginationControl
          defaultSize={size}
          onNextPage={next}
          onPreviousPage={previous}
          onFirstPage={firstPage}
          onLastPage={lastPage}
          hasFirstPage={hasFirstPage}
          hasLastPage={hasLastPage}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          position={paginationPosition}
          onUpdateSize={updateSize}
        />
      )}
    </Box>
  )
}
